import HMOApi, {COHORTS, LETTERS} from "store/api/HMOApi";

const BASE_URL = "/letter";

export const ProgramApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchLettersForCareProvider: build.query({
            query: (careProviderId) => ({url: `${BASE_URL}/care-provider/${careProviderId}`}),
            method: "GET",
            providesTags: [LETTERS],
        }),
        fetchDownloadUrlForLetter: build.query({
            query: (letterId) => ({url: `${BASE_URL}/${letterId}`}),
            method: "GET",
        }),
        getPatientsLetterData: build.mutation({
            query: (patientIds) => ({
                url: `${BASE_URL}/patient-data`,
                method: "POST",
                data: patientIds
            })
        })
    })
});

export const {
    useFetchLettersForCareProviderQuery,
    useGetPatientsLetterDataMutation,
} = ProgramApi;
